//
// Helpers
//

.object-fit-cover {
  object-fit: cover;
}

.border-bold {
  border-width: rem(2);
}

.cursor-pointer {
  cursor: pointer;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
