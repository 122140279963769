//
// Integration with Material Icons library
//

.material-icons {
  vertical-align: middle;
}

.material-icons.material-icons-size-auto {
  font-size: 1em;
}
