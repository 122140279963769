//
// Navbar
//

.navbar {
  font-family: $font-family-secondary;
  letter-spacing: $letter-spacing;
  font-weight: 700;
  font-size: rem(12);
  line-height: 1.5;

  .navbar-brand {
    font-family: $font-family-base;
    letter-spacing: 0;
  }

  .navbar-nav {
    margin-top: $navbar-padding-y/2;
  }

  .nav-link {
    @include transition;
    outline: none;
  }

  .navbar-spacer {
    width: $navbar-nav-link-padding-x;
    font-size: 0;
  }

  .dropdown .dropdown-item {
    color: inherit;
    font-family: $font-family-secondary;
    letter-spacing: $letter-spacing;
    text-transform: uppercase;
    font-weight: bold;
    font-size: rem(nth($typography-sizings, 1));
  }
}

.navbar-dark .nav-item {
  &.dropdown .dropdown-toggle::after {
    @include transition;
    color: rgba($white, .5);
  }

  &.dropdown.show .dropdown-toggle::after {
    color: $white;
    transform: rotate(180deg);
  }
}

// custom styles for regular navbar with expand option
@each $breakpoint_name in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint_name) {
    .navbar-expand#{breakpoint-infix($breakpoint_name)} {
      padding-top: 0;
      padding-bottom: 0;

      .navbar-nav .nav-link, .navbar-text {
        padding: $navbar-padding-y 0;
      }

      .navbar-nav {
        margin: 0 $navbar-nav-link-padding-x/2 * -1;

        .nav-link {
          position: relative;
          padding-left: $navbar-nav-link-padding-x/2;
          padding-right: $navbar-nav-link-padding-x/2;

          &:before {
            @include transition;
            position: absolute;
            content: '';
            height: rem(3);
            left: $navbar-nav-link-padding-x/2;
            right: $navbar-nav-link-padding-x/2;
            bottom: 0;
            background: transparent;
          }
        }

        .nav-link.active:before,
        .nav-item.active .nav-link:before,
        .nav-link:hover:before {
          background-color: $primary;
        }
      }
    }
  }
}

// custom styles for short navbar with expand option
@each $breakpoint_name in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint_name) {
    .navbar-short.navbar-expand#{breakpoint-infix($breakpoint_name)} {
      padding-top: $short-navbar-padding-y/2;
      padding-bottom: $short-navbar-padding-y/2;

      &, .navbar-collapse {
        flex-wrap: wrap;
      }

      .navbar-nav {
        margin: 0 $navbar-nav-link-padding-x/4 * -1;
        flex-wrap: wrap;

        .nav-item {
          padding: 0 $navbar-nav-link-padding-x/4;

          &:before {
            display: none;
          }
        }

        .dropdown-menu {
          margin-left: $navbar-nav-link-padding-x/4;
          margin-right: $navbar-nav-link-padding-x/4;
        }

        .dropdown.dropdown-flat .dropdown-menu {
          margin-left: -1 * ($dropdown-item-padding-x - $navbar-nav-link-padding-x/4);
          margin-right: -1 * ($dropdown-item-padding-x - $navbar-nav-link-padding-x/4);
        }
      }

      .navbar-nav .nav-link, .navbar-text {
        padding: $short-navbar-padding-y/2 0;
      }
    }
  }
}

.navbar-short {
  .nav-link:before {
    display: none;
  }

  .navbar-spacer {
    width: $navbar-nav-link-padding-x/2;
  }
}
